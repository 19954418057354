// import { indexOf } from "core-js/core/array";
import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isDashboardLoading: true,
    jwtToken: null,
    selectedIcon: "ES - Guia de tallas",
    sizeCharts: [],
    templates: [],
    //To be validated after checking Shopify's Product API
    product_links: [
      {
        shopId: "",
        productId: "",
        collectionId: "",
        sizeChartId: "",
      },
    ],
    shop: {
      shopId: "",
      plan: "",
    },
    chartItems: [],
    chartUnits: [],
    sizechartRowHeader: [],
    sizechartColHeader: [],
    sizechartData: [],
    sizechartUnit: {},
    sizechartDeletedRow: [],
    sizechartDeletedColumn: [],
    header_color: "#000000",
    header_text: "#ffffff",
    zebra_lines: "#E5E5E5",
    focus: "#CCCDCD",
    color_settings_id: null,
    shop_profile: {
      id: 0,
      shopname: '',
      plan_id: 4,
      shopify_charge_status: 'pending',
      city: '',
      country: '',
      country_code: '',
      country_name: '',
      name: '',
      shop_owner: '',
      user_role: 0,
      size_popup_version: 1,
      created_at: null,
      has_rated_app: true,
      app_rating: 0,
      popup_setting_show_message: "",
      popup_setting_table_borders: "tunnel",
    },
    infiniteLoading: {
      infiniteId: +new Date(),
      items: [],
      itemCount: 0,
    },
  },
  mutations: {

    upsertSizeChart(state, updatedSizeChart) {
      const index = state.sizeCharts.findIndex(sizeChart => sizeChart.id === updatedSizeChart.id);
      if (index !== -1) {
        Vue.set(state.sizeCharts, index, updatedSizeChart);
      }
      else {
        state.sizeCharts.unshift(updatedSizeChart);
      }
    },
    incrementInfiniteId (state) {
      state.infiniteLoading.infiniteId +=1;
    },
    updateInfiniteLoading (state, payload) {
      state.infiniteLoading = {...payload};
    },
    addItemToInfiniteLoading (state, payload) {
      state.infiniteLoading.items.push(...payload);
    },
    updateIsDashboardLoading (state, payload) {
      state.isDashboardLoading = payload;
    },
    deleteSizeChart (state, payload) {
      state.sizeCharts.splice(payload, 1);
    },
    duplicateSizeChart (state, payload) {
      state.sizeCharts.push(payload);
    },
    toggleSizeChartStatus (state, id) {
      let chart = state.sizeCharts.filter((chart) => chart.id === id)[0];
      chart.published = !chart.published;
    },
    EditChart (state, { chart, index }) {
      state.sizeCharts[index] = chart;
    },
    saveSelectedIcon (state, payload) {
      state.selectedIcon = payload;
    },
    updateChartItems (state, payload) {
      state.chartItems = [...payload];
    },
    updateChartUnits (state, payload) {
      state.chartUnits = [...payload];
    },
    updateSizechartRowHeader (state, payload) {
      state.sizechartRowHeader = [...payload];
    },
    updateSizechartColHeader (state, payload) {
      state.sizechartColHeader = [...payload];
    },
    updateSizechartData (state, payload) {
      state.sizechartData = [...payload];
    },
    updateSizechartUnit (state, payload) {
      state.sizechartUnit = { ...payload };
    },
    updateSizechartDeletedRow (state, payload) {
      state.sizechartDeletedRow = [...payload];
    },
    updateSizechartDeletedColumn (state, payload) {
      state.sizechartDeletedColumn = [...payload];
    },
    updateChartColorSettings (state, payload) {
      state.header_color = payload.header_background;
      state.header_text = payload.header_text;
      state.zebra_lines = payload.zebra_lines;
      state.focus = payload.focus;
      state.color_settings_id = payload.id;
    },
    updateShopProfile (state, payload) {
      state.shop_profile = {...payload};
    },
    updateSizeCharts (state, payload) {
      state.sizeCharts = [...payload];
    },
    addSizeChart (state, payload) {
      state.sizeCharts.unshift(payload);
    },
    updateTemplates (state, payload) {
      state.templates = [...payload];
    },
    updateHeaderColor (state, payload) {
      axios
        .patch(process.env.VUE_APP_SERVER_URL + `/color_settings/${state.color_settings_id}/?shopname=${localStorage.getItem("shop")}`, { header_background: payload })
        .then((res) => {
          state.header_color = payload;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateHeaderText (state, payload) {
      axios
        .patch(process.env.VUE_APP_SERVER_URL + `/color_settings/${state.color_settings_id}/?shopname=${localStorage.getItem("shop")}`, { header_text: payload })
        .then((res) => {
          state.header_text = payload;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateZebraLines (state, payload) {
      axios
        .patch(process.env.VUE_APP_SERVER_URL + `/color_settings/${state.color_settings_id}/?shopname=${localStorage.getItem("shop")}`, { zebra_lines: payload })
        .then((res) => {
          state.zebra_lines = payload;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateFocus (state, payload) {
      axios
        .patch(process.env.VUE_APP_SERVER_URL + `/color_settings/${state.color_settings_id}/?shopname=${localStorage.getItem("shop")}`, { focus: payload })
        .then((res) => {
          state.focus = payload;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveShopProfile (state, payload) {
      axios
        .patch(process.env.VUE_APP_SERVER_URL + `/shop_profile?shopname=${localStorage.getItem("shop")}`, payload)
        .then((res) => {
          state.shop_profile = payload;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  actions: {

    upsertSizeChart({ commit }, updatedSizeChart) {
      updatedSizeChart.title = updatedSizeChart.name;
      commit('upsertSizeChart', updatedSizeChart);
    },

    incrementInfiniteId ({ commit, getters }) {
      commit("incrementInfiniteId");
    },
    resetInfiniteLoading ({commit, getters}) {
      commit("updateInfiniteLoading", 
        { 
          items: [],
          itemCount: 0,
          infiniteId: +new Date(),
        }
      );
    },
    addItemToInfiniteLoading ({ commit, getters }, item) {
      commit("addItemToInfiniteLoading", item);
    },

    updateInfiniteLoading ({ commit, getters }, payload) {
      commit("updateInfiniteLoading", payload);
    },

    // Duplicate Chart
    duplicateSizeChart ({ commit, getters }, id) {
      // To Avoid Code Duplication
      let originChart = getters.getChart(id);
      let duplicateChart = {
        id: Date.now(),
        title: "Copy of " + originChart.title,
        published: false,
        content: originChart.content,
      };

      commit("duplicateSizeChart", duplicateChart);
    },

    // Delete Chart
    deleteSizeChart ({ commit, getters }, id) {

      let index = this.state.sizeCharts.findIndex(sizeChart => sizeChart.id === id);

      commit("deleteSizeChart", index);
    },
    
    addSizeChart ({ commit, getters }, payload) {
      commit("addSizeChart", payload);
    },
    
    EditChart ({ commit, getters }, payload) {
      
      let index = this.state.sizeCharts.findIndex(sizeChart => sizeChart.id === payload.id);

      if (index !== -1) {
        commit("EditChart", {
          payload,
          index,
        });
      } else {
        commit("duplicateSizeChart", payload);
      }
    },
    
    // Create new Chart from a Template
    newSizeChartFromTemplate ({ commit, getters }, id) {
      let template = getters.getTemplate(parseInt(id));
      let newChart = {
        id: Date.now(),
        title: "Copy of " + template.title,
        published: false,
        content: template.content,
      };
      return newChart;
    },
    saveSelectedIcon ({ commit }, payload) {
      commit("saveSelectedIcon", payload);
    },
    useAuthToken () {
      const token = localStorage.getItem('smart_size_token')
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      }
    },
    fetchChartItems ({ commit }) {
      axios
        .get(process.env.VUE_APP_SERVER_URL + "/template/chartitems")
        .then((res) => {
          commit("updateChartItems", res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fetchChartTableContent ({ commit }, chartid) {
      axios
      .get(process.env.VUE_APP_SERVER_URL + "/template/sizechart", {
        params: { chart_id: chartid, shop: localStorage.getItem("shop") },
      })
      .then((response) => {

        if (response.status === '500') {
          this.alertdata = "ERROR LOADING CHART. PLEASE TRY AGAIN OR CONTACT SUPPORT";
          this.alert = true;
          return null;
        };

        const { data } = response;

        console.log(`received response from /sizechart: ${data}`);

        commit("updateSizechartRowHeader", [...data.chart_items]);
        commit("updateSizechartColHeader", [...data.chart_sizes]);
        commit("updateSizechartData", [...data.chart_measurements]);

      })
      .catch((error) => console.log(error));
    },
    fetchChartUnits ({ commit }) {
      axios
        .get(process.env.VUE_APP_SERVER_URL + "/template/chartunits")
        .then((res) => commit("updateChartUnits", res.data));
    },
    updateSizechartRowHeader ({ commit }, payload) {
      commit("updateSizechartRowHeader", payload);
    },
    updateSizechartColHeader ({ commit }, payload) {
      commit("updateSizechartColHeader", payload);
    },
    updateSizechartData ({ commit }, payload) {
      commit("updateSizechartData", payload);
    },
    updateSizechartUnit ({ commit }, payload) {
      commit("updateSizechartUnit", payload);
    },
    updateSizechartDeletedRow ({ commit }, payload) {
      commit("updateSizechartDeletedRow", payload);
    },
    updateSizechartDeletedColumn ({ commit }, payload) {
      commit("updateSizechartDeletedRow", payload);
    },
    fetchChartColorSettings ({ commit }) {
      axios
        .get(process.env.VUE_APP_SERVER_URL + `/color_settings?shopname=${localStorage.getItem("shop")}`)
        .then((res) => commit("updateChartColorSettings", res.data));
    },
    fetchShopProfile ({ commit }) {
      axios
        .get(process.env.VUE_APP_SERVER_URL + `/shop_profile?shopname=${localStorage.getItem("shop")}`)
        .then((res) => commit("updateShopProfile", res.data));
    },
    fetchSizeCharts({ commit }) {
      //console.log("fetching size charts...");

      commit("updateIsDashboardLoading", true);
      axios
        .get(process.env.VUE_APP_SERVER_URL + `/template/usercharts?shop=${localStorage.getItem("shop")}`)
        .then(
          (res) => {
            if (res.data != null) {
              res.data.map((chart) => {
                chart.title = chart.name;
              });
             commit("updateSizeCharts", res.data);
             commit("updateIsDashboardLoading", false);

            }
          })
      },
    fetchSingleSizeChart ({ commit }, chart_id) {
      axios
        .get(process.env.VUE_APP_SERVER_URL + `/template/chart?shop${localStorage.getItem("shop")}&chart_id=${chart_id}`)
        .then((res) => this.dispatch("updateSizeChart", res.data));
    },
    updateTemplates ({ commit, getters }, payload) {
      commit("updateTemplates", payload);
    },
    
  },
  modules: {},
  getters: {
    getInfiniteLoading: (state) => {
      return state.infiniteLoading;
    },
    getInfiniteId: (state) => {
      return state.infiniteLoading.infiniteId;
    },
    getChart: (state) => (id) => {
      let answer = JSON.parse(JSON.stringify(state.sizeCharts.filter((originChart) => originChart.id === id)[0]))
      return answer;
    },
    getTemplate: (state) => (id) => {
      return state.templates.filter((originChart) => originChart.id === id)[0];
    },
    getTemplates: (state) => {
      return state.templates;
    },
    getType: (state) => {
      return state.selectedIcon;
    },
    getChartItems: (state) => {
      return state.chartItems;
    },
    getChartUnits: (state) => {
      return state.chartUnits;
    },
    getShopProfile: (state) => {
      return state.shop_profile;
    },
    getSizeCharts: (state) => {
      return state.sizeCharts;
    },
    getSizeCharts: (state) => {
      return state.sizeCharts;
    },
    getisDashboardLoading: (state) => {
      return state.isDashboardLoading;
    },
  },
});
