<template>
  <v-app>
    <Navdrawer @offCanvasMain="offCanvasMain" />
    <v-expand-x-transition>
      <v-main :class="[addMargin ? 'aMargin' : 'mMargin']">
        <router-view />
      </v-main>
    </v-expand-x-transition>
  </v-app>
</template>

<script>
import Navdrawer from "@/components/Navbar/Navdrawer";
import "vue-swatches/dist/vue-swatches.css";

export default {
  name: "App",

  components: {
    Navdrawer,
  },

  data: () => ({
    //
    addMargin: false,
    publicPath: process.env.BASE_URL,
  }),
  methods: {
    offCanvasMain(mini) {
      if (mini) {
        this.addMargin = true;
      } else {
        this.addMargin = false;
      }
    },
  },
  created() {
    this.$store.dispatch("useAuthToken");
    this.$store.dispatch("fetchSizeCharts");
    this.$store.dispatch("fetchChartItems");
    this.$store.dispatch("fetchChartUnits");
    this.$store.dispatch("fetchChartColorSettings");
    this.$store.dispatch("fetchShopProfile");

    console.log(
      "%c SmartSize Frontend: v1 loaded - 2024.02.05 build",
      "background: black; color: yellow"
    );

  },
};
</script>

<style>
@import "`${publicPath}sizefox.css`";

* {
  text-transform: none !important;
  margin: 0;
  /* padding: 0; */
}

@media (min-width: 1904px) {
  .container {
    /* max-width: 1700px; */
    max-width: 1900px;
  }
}


.v-btn {
  letter-spacing: 2 !important;
}
.mMargin {
  /* margin-left: 50px; */
  margin-left: 20px;

}
.aMargin {
  /* margin-left: 150px; */
  margin-left: 20px;

}

@media (max-width: 600px) {
  .mMargin {
    margin-left: 0px;
  }
  .aMargin {
    margin-left: 0px;
  }
}

@media (max-width: 600px) {
  .mMargin {
    margin-left: 0px;
  }
  .aMargin {
    margin-left: 0px;
  }
}

.v-select-list .v-list-item .v-list-item__title {
  text-align: left !important;
}

/* .v-alert {
  opacity: 0;
  visibility: hidden;
  height: 56px;
}
.v-alert.active {
  opacity: 1;
  visibility: visible;
} */
</style>
