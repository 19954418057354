<template>
  <div class="navdrawer" style="">
    <div :class="['navigation', mini ? 'active' : '']">
      <ul style="height: 100%">
        <v-app-bar-nav-icon
          class="baricon"
          @click.stop="toggleMini"
        ></v-app-bar-nav-icon>
        <li>
          <div class="list-item" @click.stop="toggleMini">
            <span class="icon">
              <img
                src="/logosizefox.svg"
                style="
                  width: 40px;
                  height: 40px;
                  object-fit: cover;
                  margin: 10px auto;
                "
                class="width: 100%;"
                alt=""
              />
            </span>
            <span class="title font-weight-bold"> SmartSize </span>

            <!-- <span class="icon ml-auto" @click.stop="toggleMini">
              <v-icon dark>mdi-chevron-left</v-icon>
            </span> -->
          </div>
        </li>

        <!-- to activate tool tip set v-on to 'on' instead of off (below)
 -->
        <li v-for="(item, i) in items" :key="i">
          <router-link :to="item.link" class="list-item" v-if="item.show">
            <v-tooltip class="tools" right z-index="7000">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" class="icon">
                  <v-icon size="20px" color="#353535">{{ item.icon }}</v-icon>

                  <!-- color="#d9e6e3" -->
                </span>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>

            <router-link :to="item.link" class="title">{{
              item.title
            }}</router-link>
          </router-link>
        </li>

        <li>
          <router-link to="/reviews" class="list-item" v-if="showReview">
            <v-tooltip class="tools" right z-index="7000">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" class="icon">
                  <v-icon size="20px" color="#353535"> mdi-star </v-icon>

                  <!-- color="#d9e6e3" -->
                </span>
              </template>
              <span>Rate us</span>
            </v-tooltip>

            <router-link to="/reviews" class="title"> Rate us </router-link>
          </router-link>
        </li>

        <li style="position: absolute; bottom: 1">
          <div class="list-item">
            <span
              class="icon"
              title="Open Sidebar"
              v-if="!mini"
              @click.stop="toggleMini"
            >
              <v-icon size="20px" color="#353535">mdi-arrow-right</v-icon>
            </span>
            <span
              class="icon"
              title="Open Sidebar"
              v-else
              @click.stop="toggleMini"
            >
              <v-icon size="20px" color="#353535">mdi-arrow-left</v-icon>
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navdrawer",
  data() {
    return {
      drawer: true,
      items: [
        {
          title: "Get started",
          icon: "mdi-play-circle-outline",
          link: "/greet",
          show: true,
        },
        {
          title: "Dashboard",
          icon: "mdi-home",
          link: "/dashboard",
          show: true,
        },
        {
          title: "New Chart",
          icon: "mdi-plus-circle-outline",
          link: "/templates",
          show: true,
        },
        /*         { title: "Care Card", icon: "mdi-tree", link: "/care" },
         */
        {
          title: "Settings",
          icon: "mdi-cog",
          link: "/icons",
          show: true,
        },
        {
          title: "Plans",
          icon: "mdi-account-multiple",
          link: "/plans",
          show: true,
        },
        {
          title: "Help",
          icon: "mdi-help-circle",
          link: "/help",
          show: true,
        },
      ],
      mini: false,
    };
  },
  computed: {
    showReview() {
      return !this.$store.getters.getShopProfile.has_rated_app; // Has not yet rated app
    },
  },
  methods: {
    toggleMini() {
      this.mini = !this.mini;
      this.$emit("offCanvasMain", this.mini);
    },
  },
};
</script>

<style lang="css" scoped>
.navdrawer {
  position: relative;
}
.navbar {
  position: relative;
}
.navigation {
  position: fixed;
  top: 0px;
  overflow: hidden;
  width: 60px;

  /* background: #f1f2f4; */
  background: #f3f5f7;

  z-index: 202;
  bottom: 0;

  transition: 0s;
}
.navigation.active {
  width: 160px;
}
ul {
  list-style: none;
  padding: 0;
}

.navigation ul {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  list-style: none;
}

.navigation ul li {
  list-style: none;
  position: relative;
  width: 100%;
}

.navigation ul li:hover {
  /* background-color: #f1b24a; */
  background-color: #d7d7d7;
}

.navigation ul li .list-item,
.navigation .exit-icon {
  position: relative;
  width: 50%;
  display: block;
  display: flex;
  text-decoration: none;
  /* color: #d9e6e3; */
  color: #000000;
  font-weight: 100;
}

.navigation ul li .list-item .icon {
  position: relative;
  display: block;
  min-width: 60px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.navigation ul li .list-item .icon i {
  font-size: 30px;
  font-weight: 100;
}

.navigation ul li .list-item .title {
  position: relative;
  display: block;
  height: 10px;
  line-height: 40px;
  white-space: nowrap;
  font-size: 14px !important;
  font-weight: 500;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #353535;
  text-decoration: none;
  font-style: normal;
}
.v-tooltip__content {
  opacity: 1 !important;
  background: #000000;
  color: #fff;
  font-weight: 600 !important;
}

.baricon {
  position: fixed;
  top: 0px;
  left: 0px;
  display: none;
}

@media (max-width: 600px) {
  .navigation.active {
    width: 100vw;
  }
  .baricon {
    display: block;
  }
  .navigation {
    width: 0px;
  }
}
</style>
