import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "app-bridge" */ "../views/AppBridge.vue"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/help",
    name: "Help",
    component: () => import(/* webpackChunkName: "help" */ "../views/Help.vue"),
  },
  {
    path: "/reviews",
    name: "Reviews",
    component: () => import(/* webpackChunkName: "reviews" */ "../views/Reviews.vue"),
  },
  {
    path: "/edit",
    name: "Edit",
    component: () => import(/* webpackChunkName: "edit-chart" */ "../views/EditChart.vue"),
  },
  {
    path: "/greet",
    name: "Welcome",
    component: () => import(/* webpackChunkName: "welcome" */ "../views/Welcome.vue"),
  },
  {
    path: "/templates",
    name: "Templates",
    component: () => import(/* webpackChunkName: "templates" */ "../views/Templates.vue"),
  },
  {
    path: "/care",
    name: "TemplatesCare",
    component: () => import(/* webpackChunkName: "templates-care" */ "../views/TemplatesCare.vue"),
  },
  {
    path: "/link",
    name: "LinkProducts",
    component: () => import(/* webpackChunkName: "link-products" */ "../views/LinkProducts.vue"),
  },
  {
    path: "/plans",
    name: "Plans",
    component: () => import(/* webpackChunkName: "plans" */ "../views/Plans.vue"),
  },
  {
    path: "/icons",
    name: "Icons",
    component: () => import(/* webpackChunkName: "icons" */ "../views/Icons.vue"),
  },
  {
    path: "/prefills",
    name: "Prefills",
    component: () => import(/* webpackChunkName: "prefills" */ "../views/Prefills.vue"),
  },
  {
    path: "/plan_upgrade",
    name: "ChangeToPaidPlan",
    component: () => import(/* webpackChunkName: "change-to-paid-plan" */ "../views/ChangeToPaidPlan.vue"),
  },
  {
    path: "/plan_downgrade",
    name: "ChangeToFreePlan",
    component: () => import(/* webpackChunkName: "change-to-free-plan" */ "../views/ChangeToFreePlan.vue"),
  },
  {
    path: "/install_error",
    name: "InstallError",
    component: () => import(/* webpackChunkName: "install-error" */ "../views/InstallError.vue"),
  },
  {
    path: "/landing",
    name: "Landing",
    component: () => import(/* webpackChunkName: "landing" */ "../views/Landing.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

export default router;
